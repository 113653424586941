import * as React from "react"
import Layout from "../components/layout";
import * as Container from "../components/container/container";
import {StaticImage} from "gatsby-plugin-image";

const IndexPage = (props) => {
    return (
        <Layout pageTitle="OdD" props={props}>
            <main id="main">
                <Container.HeaderPart background="white no-image">
                    <div className="headline">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <h1>Das Gedenkobjekt</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container.HeaderPart>
                <Container.Part background="white">
                    <Container.Row>
                        <div className="col-12">
                            <p>
                                <strong><em>
                                    „Vereint Vergangenheit, Gegenwart und Zukunft“
                                </em></strong>
                            </p>
                            <p>
                                Der Bayerische Landtag wird Orte, an denen bayerische Demokratiegeschichte geschrieben
                                wurde, im öffentlichen Raum mit einem Gedenkobjekt sichtbar machen und so auf deren
                                historische Bedeutung hinweisen.
                            </p>
                            <p>
                                Für die Gestaltung des Gedenkobjektes hat der Bayerische Landtag unter enger Einbindung
                                des wissenschaftlichen Beirates und mit fachlicher Unterstützung des Berufsverbandes
                                der Bildenden Künstlerinnen und Künstler – Landesverband Bayern e.V. einen Wettbewerb
                                ausgerichtet.
                            </p>
                            <p>
                                Aus den vorgelegten Entwürfen der Bewerber hat das Präsidium des Bayerischen Landtages
                                nach Beratung durch den wissenschaftlichen Beirat und der sachverständigen Stellungnahme
                                von Frau Benita Meißner, Geschäftsführerin und Kuratorin der Deutsche Gesellschaft für
                                christliche Kunst e.V. einstimmig den Entwurf der Künstlergemeinschaft Ackstaller &
                                Schweikl
                                aus Kirchdorf (Hallertau) ausgewählt.
                            </p>
                        </div>
                    </Container.Row>
                    <Container.Row>
                        <div className="col-12" id="trailer">
                            <div className="inner-container">
                                <figure className="video">
                                    <div className="yt-container yt-enabled">
                                        <Container.Video
                                            url={"/videos/Filmmaterial/013_Kunstobjekt/211011_Kunstobjekt ohne UT.mp4"}
                                            poster={"/images/Videowallpaper/kunstobjekt.jpg"}/>
                                    </div>
                                </figure>
                            </div>
                        </div>
                    </Container.Row>
                    <Container.Row>
                        <div className="col-6">
                            <h2>Goldfarbener Würfel, gefasst auf einer Steinsäule</h2>
                            <p>
                                Ausgangspunkt der Idee zum Gedenkobjekt bildet der Verfassungswürfel. Gleichen Längen
                                und die daraus resultierenden gleich großen Quadrate unterstreichen den
                                Gleichheitsgedanken – damals, wie auch heute und in Zukunft. Das Gedenkobjekt vereint
                                damit auf der einen Seite den Bezug zur Vergangenheit, fügt sich aber auch in die
                                Gegenwart
                                ein und ist vor allem aber richtungsweisend für die Zukunft.
                            </p>
                            <p>
                                Der Würfel wird aus fünf gleich großen Tafeln zusammengesetzt. Jede Seite des Würfels
                                greift einen anderen, wichtigen Aspekt auf; so sind rund herum verschiedene, in die
                                Platte
                                vertiefte Schriftzüge zu lesen. Die Oberfläche des Würfels ist anpoliert, sodass sich
                                das komplette Umfeld darin widerspiegelt. So entsteht ein Ortsbezug des Denkmals, der
                                in seiner Weise einzigartig ist. Alles, was um das Denkmal herum passiert, wird Teil
                                von ihm. Der goldene Würfel thront auf einem Sockel aus Kelheimer Marmor, einem
                                heimischen
                                Kalkstein, der in ganz Bayern in vielen historischen Bauten verwendet wurde.
                            </p>
                            <p>
                                Der golden strahlende Würfel auf dem hellen Sockel vermittelt gleichzeitig den Eindruck
                                einer von weitem sichtbaren Pinnadel, die, wie auf einer Landkarte, etwas Wichtiges,
                                den „Ort der Demokratie“, markiert.
                            </p>
                        </div>
                        <div className="col-6">
                            <figure className="lightbox">
                                <a className="glightbox" data-gallery="gallery1"
                                   href={"/images/kunstobjekt/PRP_0244.JPG"}
                                   data-glightbox="title: ; descPosition: bottom;">
                                    <StaticImage src="../../static/images/kunstobjekt/PRP_0244.JPG" width={350} layout="fixed" placeholder="none" alt=""/>
                                </a>
                            </figure>
                            <figure className="lightbox">
                                <a className="glightbox" data-gallery="gallery1"
                                   href={"/images/kunstobjekt/PRP_0245.JPG"}
                                   data-glightbox="title: ; descPosition: bottom;">
                                    <StaticImage src="../../static/images/kunstobjekt/PRP_0245.JPG" width={350} layout="fixed" placeholder="none" alt=""/>
                                </a>
                            </figure>
                        </div>
                    </Container.Row>
                </Container.Part>
            </main>
        </Layout>
    )
}

export default IndexPage
